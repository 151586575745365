var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-card-title',{staticClass:"ma-0 py-1"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-upload")]),_vm._v(" Uploads "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 ma-0",staticStyle:{"height":"calc(100vh - 120px)","overflow":"auto"},attrs:{"full-width":"","headers":_vm.headers,"items":_vm.uploads,"loading":_vm.loading,"items-per-page":25,"search":_vm.search,"readonly":true,"footer-props":{
      'items-per-page-options': [10,25,50]
    },"sort-by":['created_at'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.user_name",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32"}},[_c('v-icon',[_vm._v("mdi-account")])],1),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(item.user_name))])]}},{key:"item.machine_name",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32"}},[_c('v-icon',[_vm._v("mdi-desktop-classic")])],1),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(item.machine_name))])]}},{key:"item.city",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.city)+", "+_vm._s(item.country))])]}},{key:"item.ip",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-mono"},[_vm._v(_vm._s(item.ip))])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"success":"","outlined":"","ml-3":""}},on),[_c('v-icon',{attrs:{"left":"","outline":"","mr-2":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(item.age)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(new Date(item.created_at).toString()))])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }