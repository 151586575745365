var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-card-title',{staticClass:"ma-0 py-1"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-document")]),_vm._v(" Master Files "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 ma-0",staticStyle:{"height":"calc(100vh - 120px)","overflow":"auto"},attrs:{"headers":_vm.headers,"items":_vm.exams,"loading":_vm.loading,"items-per-page":25,"search":_vm.search,"readonly":true,"footer-props":{
      'items-per-page-options': [10,25, 50]
    },"sort-by":['updated_at'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"ellipsis"},[_c('v-avatar',{attrs:{"size":"32"}},[_c('v-icon',{attrs:{"color":item.files_exist? 'success': 'error'}},[_vm._v("mdi-file-document")])],1),_c('span',{staticClass:"pl-4 ellipsis"},[_vm._v(_vm._s(item.number))])],1)]}},{key:"item.is_expired",fn:function(ref){
    var item = ref.item;
return [(item.is_expired)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-checkbox-marked")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.updated_at",fn:function(ref){
    var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"success":"","outlined":"","ml-3":""}},on),[_c('v-icon',{attrs:{"left":"","outline":"","mr-2":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(item.age)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(new Date(item.updated_at).toString()))])])],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.showPermissionsModal(item)}}},on),[_vm._v("mdi-timetable")])]}}],null,true)},[_c('span',[_vm._v("Assign access permissions")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.expireExam(item)}}},on),[_vm._v("mdi-calendar-remove")])]}}],null,true)},[_c('span',[_vm._v("Toggle expired status of this master file")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.deleteExam(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete this master file")])])]}}],null,true)}),_c('v-overlay',{attrs:{"value":_vm.processing}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","dark":""}})],1),_c('exam-access-modal',{attrs:{"exam":_vm.exam},model:{value:(_vm.show_permissions),callback:function ($$v) {_vm.show_permissions=$$v},expression:"show_permissions"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }