var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-card-title',{staticClass:"ma-0 py-1"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account")]),_vm._v("Users "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.createUser($event)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Create New User")])])],1),_c('v-data-table',{staticClass:"elevation-1 ma-0",staticStyle:{"height":"calc(100vh - 120px)","overflow":"auto"},attrs:{"full-width":"","headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"items-per-page":25,"search":_vm.search,"readonly":true,"show-expand":"","single-expand":"","item-key":"id","footer-props":{
      'items-per-page-options': [10,25,50]
    },"sort-by":['updated_at'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [(item.activity && item.activity.length > 0)?_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('v-card',{staticClass:"my-1",attrs:{"flat":""}},[_c('v-card-text',[_c('activity-data-table',{attrs:{"userName":item.name,"userType":item.type,"activities":item.activity}})],1)],1)],1):_c('td',{staticClass:"justify-center align-center text-center",attrs:{"colspan":_vm.headers.length}},[_vm._v("No activity")])]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32"}},[_c('v-icon',[_vm._v("mdi-account")])],1),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(item.name))])]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.type),"dark":""}},[_vm._v(_vm._s(item.type == 1 ? 'Admin' : (item.type == 2 ? 'Associate' : 'User')))])]}},{key:"item.updated_at",fn:function(ref){
    var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"success":"","outlined":"","ml-3":""}},on),[_c('v-icon',{attrs:{"left":"","outline":"","mr-2":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(item.age)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(new Date(item.updated_at).toString()))])])],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.editUser(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Edit user record")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.deleteUser(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete this user")])])]}}])}),_c('user-modal',{attrs:{"user":_vm.user,"editMode":_vm.editMode},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('v-overlay',{attrs:{"value":_vm.processing}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","dark":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }