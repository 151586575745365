var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-card-title',{staticClass:"ma-0 py-1"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-download")]),_vm._v("Downloads "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":_vm.fetchDownloads}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh downloads")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.show_delete_snapshots_modal = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-camera-off")])],1)]}}])},[_c('span',[_vm._v("Delete past snapshots in date range")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.show_delete_snapshots_modal),callback:function ($$v) {_vm.show_delete_snapshots_modal=$$v},expression:"show_delete_snapshots_modal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete Snapshots")]),_c('v-card-text',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.delete_snapshots_start),callback:function ($$v) {_vm.delete_snapshots_start=$$v},expression:"delete_snapshots_start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.delete_snapshots_start_menu),callback:function ($$v) {_vm.delete_snapshots_start_menu=$$v},expression:"delete_snapshots_start_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.delete_snapshots_start_menu = false}},model:{value:(_vm.delete_snapshots_start),callback:function ($$v) {_vm.delete_snapshots_start=$$v},expression:"delete_snapshots_start"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.delete_snapshots_end),callback:function ($$v) {_vm.delete_snapshots_end=$$v},expression:"delete_snapshots_end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.delete_snapshots_end_menu),callback:function ($$v) {_vm.delete_snapshots_end_menu=$$v},expression:"delete_snapshots_end_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.delete_snapshots_end_menu = false}},model:{value:(_vm.delete_snapshots_end),callback:function ($$v) {_vm.delete_snapshots_end=$$v},expression:"delete_snapshots_end"}})],1)],1),_c('v-card-actions',{staticClass:"right"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteSnapshots}},[_vm._v(" Delete ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.show_delete_snapshots_modal = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 ma-0",staticStyle:{"height":"calc(100vh - 120px)","overflow":"auto"},attrs:{"full-width":"","headers":_vm.headers,"items":_vm.downloads,"loading":_vm.loading,"items-per-page":25,"search":_vm.search,"readonly":true,"footer-props":{
      'items-per-page-options': [10,25,50]
    },"sort-by":['created_at'],"sort-desc":[true],"single-expand":true,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.user_name",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32"}},[_c('v-icon',[_vm._v("mdi-account")])],1),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(item.user_name))])]}},{key:"item.machine_name",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32"}},[_c('v-icon',[_vm._v("mdi-desktop-classic")])],1),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(item.machine_name))])]}},{key:"item.ip",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-mono"},[_vm._v(_vm._s(item.ip))])]}},{key:"item.city",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.city)+", "+_vm._s(item.country))])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"success":"","outlined":"","ml-3":""}},on),[_c('v-icon',{attrs:{"left":"","outline":"","mr-2":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(item.age)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(new Date(item.created_at).toString()))])])],1)]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[(_vm.media_loading)?_c('v-layout',{staticClass:"justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-expand-transition',[_c('images-grid',{attrs:{"images":_vm.media}})],1)],1)]}},{key:"item.data-table-expand",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.expandSnapshots(item)}}},[_vm._v("mdi-camera")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }