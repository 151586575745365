var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":850},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Master File Permissions")]),_c('v-spacer'),(_vm.processing)?_c('v-progress-circular',{attrs:{"indeterminate":"","dark":""}}):_vm._e()],1),_c('v-card-text',{staticClass:"mt-2"},[_c('v-data-table',{staticClass:"elevation-1 ma-0",attrs:{"headers":_vm.headers,"items":_vm.accesses,"items-per-page":25,"footer-props":{
            'items-per-page-options': [10,25,50]
          },"sort-by":['updated_at'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.user_name",fn:function(ref){
          var item = ref.item;
return [(item.added)?_c('v-select',{attrs:{"items":_vm.downloaders,"item-text":"name","item-value":"id"},model:{value:(item.user_id),callback:function ($$v) {_vm.$set(item, "user_id", $$v)},expression:"item.user_id"}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.user_name))])]}},{key:"item.start",fn:function(ref){
          var item = ref.item;
return [_c('date-picker-icon',{model:{value:(item.start),callback:function ($$v) {_vm.$set(item, "start", $$v)},expression:"item.start"}})]}},{key:"item.end",fn:function(ref){
          var item = ref.item;
return [_c('date-picker-icon',{model:{value:(item.end),callback:function ($$v) {_vm.$set(item, "end", $$v)},expression:"item.end"}})]}},{key:"item.updated_at",fn:function(ref){
          var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"success":"","outlined":"","ml-3":""}},on),[_c('v-icon',{attrs:{"left":"","outline":"","mr-2":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(item.age)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.updated_at === null ? "New" : new Date(item.updated_at).toString()))])])],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-checkbox',{staticClass:"align-center justify-center hide-details",attrs:{"color":"red"},model:{value:(item.deleted),callback:function ($$v) {_vm.$set(item, "deleted", $$v)},expression:"item.deleted"}})]}}])}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","dark":""}})],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addAccess($event)}}},[_vm._v("Allow New User")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.updateAccesses($event)}}},[_vm._v("Update")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.close($event)}}},[_vm._v("Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }