var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 px-0 mx-0",attrs:{"headers":_vm.computedHeaders,"items-per-page":10,"items":_vm.activities,"search":_vm.search,"readonly":true,"footer-props":{
  'items-per-page-options': [10,25, 50]
  },"sort-desc":true,"caption":_vm.userName + ' activities',"sort-by":"updated_at","full-width":""},scopedSlots:_vm._u([{key:"item.ip",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-mono"},[_vm._v(_vm._s(item.ip))])]}},{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c('v-chip',{staticClass:"my-2",attrs:{"outlined":"","color":_vm.type2color(item.type),"text-color":_vm.type2color(item.type)}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.type2icon(item.type)))])],1),_vm._v(" "+_vm._s(_vm.type2text(item.type))+" ")],1)]}},{key:"item.updated_at",fn:function(ref){
  var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-chip',{attrs:{"success":"","outlined":"","ml-3":""}},[_c('v-icon',{attrs:{"left":"","outline":"","mr-2":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(item.updated_at)+" ")],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }